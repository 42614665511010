import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AdminFileInput from './AdminFileInput';
import { useFileUpload, deleteFile, formFileName } from '../../firestore';
import SuccessStatus from './SuccessStatus';
import DeleteButton from './DeleteButton';
import { Draggable } from 'react-beautiful-dnd';

const useStyles = makeStyles({
  container: {
    marginBottom: '1em',
    fontSize: '0.8em',
    backgroundColor: 'white',
    border: '1px solid lightgrey',
    borderRadius: '3px',
    padding: '8px 8px 8px 20px'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    marginRight: '10px',
  },
});

export default function AdminTrackInput({
  id,
  index,
  name,
  fileName,
  updateTrack,
  removeTrack,
}) {
  const classes = useStyles();

  const [trackFile, setTrackFile] = useState(null);
  const [prevURL, setPrevURL] = useState('');

  const [trackFileSuccess, downloadURL, loading] = useFileUpload(
    trackFile,
    fileName,
    'albums/tracks'
  );

  useEffect(() => {
    if (!downloadURL || downloadURL === prevURL) return;

    setPrevURL(downloadURL);
    updateTrack('audioSrc', downloadURL, id);
  }, [downloadURL, updateTrack, id]);

  function handleTrackInputChange(files) {
    if (files && files[0]) {
      const trackFile = files[0];
      setTrackFile(trackFile);
      updateTrack('fileName', formFileName(trackFile.name), id);
    }
  }

  function handleFileDelete() {
    if (fileName) {
      deleteFile(fileName, 'albums/tracks')
        .then(() => {
          console.log('File deleted!');
          removeTrack(id);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      removeTrack(id);
    }
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          className={classes.container}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <section className={classes.inputContainer}>
            <TextField
              className={classes.item}
              placeholder="Nimi"
              value={name}
              name="name"
              onChange={(e) => updateTrack(e.target.name, e.target.value, id)}
            />

            <AdminFileInput
              className={classes.item}
              name="Lataa kappale"
              accept="audio/*"
              handleChange={handleTrackInputChange}
              id="track-input"
              disabled={loading}
            >
              {loading && (
                <CircularProgress style={{ position: 'absolute' }} size={20} />
              )}
            </AdminFileInput>
            <DeleteButton onClick={handleFileDelete} />
            <SuccessStatus success={trackFileSuccess} />
          </section>
          {fileName && <p>{fileName}</p>}
        </div>
      )}
    </Draggable>
  );
}
