import React, { useState } from 'react';
import {
  Card,
  TextField,
  makeStyles,
  CardHeader,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { firebase, useAuthenticated } from '../../firestore';
import { navigate } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '500px',
    margin: 'auto',
    marginTop: '3em'
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  },
  formField: {
    marginBottom: '30px',
  },
  progress: {
    position: 'absolute',
  },
  errorMsg: {
    textAlign: 'center',
    color: 'red'
  }
}));

export default function AdminLogin(props) {
  const classes = useStyles();

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const authenticated = useAuthenticated();

  function handleSubmit(event) {
    setLoading(true);
    setError(null);

    event.preventDefault();

    firebase
      .auth()
      .signInWithEmailAndPassword(userName, password)
      .catch((err) => {
        setLoading(false);
        setError('Käyttäjätunnus tai salasana väärin');
      });
  }

  if (authenticated) {
    navigate("/Admin/hub");
  }

  return (
    <Card className={classes.card}>
      <CardHeader style={{ textAlign: 'center' }} title="Kirjaudu sisään hallinnointipaneeliin" />
      {error && <p className={classes.errorMsg}>{error}</p>}
      <form className="pk-form" onSubmit={handleSubmit}>
        <TextField
          placeholder="Käyttäjätunnus"
          type="email"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          className={classes.formField}
          autoComplete="email"
        />
        <TextField
          placeholder="Salasana"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={classes.formField}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
        >
          Kirjaudu
          {loading && (
            <CircularProgress className={classes.progress} size={20} />
          )}
        </Button>
      </form>
    </Card>
  );
}
