import React from 'react';
import { navigate } from 'gatsby';
import { useAuthenticated } from '../../firestore';

export default function PrivateRoute({
  component: Component,
  location,
  ...rest
}) {
  const authenticated = useAuthenticated();

  if (authenticated === null) {
    return null;
  }

  if (!authenticated && location.pathname !== `/Admin/login`) {
    navigate('/Admin/login');
    return null;
  }

  return <Component {...rest} />;
}
