import React, { useState, useEffect } from 'react';
import AdminFileInput from './AdminFileInput';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SuccessStatus from './SuccessStatus';
import { useFileUpload, deleteFile, formFileName } from '../../firestore';
import DeleteButton from './DeleteButton';

const useStyles = makeStyles({
  coverUpload: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    margin: '30px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '10px',
  },
  coverUploadImg: {
    objectFit: 'contain',
    border: '1px solid #ccc',
  },
});

export default function AdminCoverUpload({
  coverURL,
  setCoverURL,
  coverName,
  setCoverName,
}) {
  const classes = useStyles();

  const [coverFile, setCoverFile] = useState(null);
  const [uploadSuccess, coverDownloadURL, loading] = useFileUpload(
    coverFile,
    coverName,
    'albums/covers'
  );

  useEffect(() => {
    if (!coverDownloadURL) return;

    setCoverURL(coverDownloadURL);
  }, [coverDownloadURL, setCoverURL]);

  function handleCoverInputChange(files) {
    const coverFile = files[0];
    setCoverFile(coverFile);
    setCoverName(formFileName(coverFile.name));
  }

  function removeCover() {
    deleteFile(coverName, 'albums/covers')
      .then(() => {
        console.log('Cover deleted!');
        setCoverURL('');
        setCoverName('');
      })
      .catch((err) => console.error(err));
  }

  return (
    <article className={classes.coverUpload}>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          marginRight: '10px',
        }}
      >
        <div>
          <SuccessStatus success={uploadSuccess} />
          <AdminFileInput
            name="Lataa kansikuva"
            accept="image/*"
            handleChange={handleCoverInputChange}
            id="cover-input"
            disabled={loading}
          >
            {loading && (
              <CircularProgress style={{ position: 'absolute' }} size={20} />
            )}
          </AdminFileInput>
        </div>
        {coverName && <DeleteButton onClick={removeCover} />}
      </section>

      <img
        className={classes.coverUploadImg}
        src={coverURL}
        alt=""
        width="300"
        height="300"
      />
    </article>
  );
}
