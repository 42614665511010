import React from 'react';
import { IconButton } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';

const useStyles = makeStyles({
  button: {
    color: red[500]
  }
});

export default function DeleteButton(props) {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.button}
      variant="outlined"
      color="secondary"
      {...props}
    >
      <Delete />
    </IconButton>
  );
}