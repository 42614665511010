import { useState, useEffect } from 'react';

const firebase = require('firebase/app');
require('firebase/firestore');
require('firebase/auth');
require('firebase/storage');

const firebaseConfig = {
  apiKey: 'AIzaSyDE5yEJfF1uX8kUjV8icauz9K9Xk4Mtmgo',
  authDomain: 'pilvikone.firebaseapp.com',
  databaseURL: 'https://pilvikone.firebaseio.com',
  projectId: 'pilvikone',
  storageBucket: 'pilvikone.appspot.com',
  messagingSenderId: '814515105903',
  appId: '1:814515105903:web:0489b3935f4471937add9a',
};

if (typeof firebase.initializeApp === 'function') {
  firebase.initializeApp(firebaseConfig);
}

let db;
if (typeof firebase.firestore === 'function') {
  db = firebase.firestore();
  db.settings({ timestampsInSnapshots: true });
}

let storage;
if (typeof firebase.storage === 'function') {
  storage = firebase.storage();
}

function useAuthenticated() {
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return authenticated;
}

function useFileUpload(file, fileName, path) {
  const [success, setSuccess] = useState(null);
  const [downloadURL, setDownloadURL] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!file || !fileName || !path) return;

    const storageRef = storage.ref();
    const pathRef = storageRef.child(`${path}/${fileName}`);

    setLoading(true);

    pathRef
      .put(file)
      .then((result) => {
        console.log('File uploaded!', result);
        setSuccess(true);
        setLoading(false);
        return result.ref.getDownloadURL();
      })
      .then((url) => setDownloadURL(url))
      .catch((err) => {
        console.error('Error in uploading file', err);
        setSuccess(false);
        setLoading(false);
      });
  }, [file, fileName, path]);

  return [success, downloadURL, loading];
}

function deleteFile(fileName, path) {
  const storageRef = storage.ref();
  const fileRef = storageRef.child(`${path}/${fileName}`);

  return fileRef.delete();
}

function formFileName(name) {
  return `${name}_${new Date().toDateString()}`.replace(/ /gi, '-');
}

function useDataAdd(collection, docName, data) {
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (!docName || !data) return;

    setLoading(true);

    db.collection(collection)
      .doc(docName)
      .set(data)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        console.error('Error while writing data to database', error);
        setSuccess(false);
      })
      .finally(() => setLoading(false));
  }, [collection, docName, data]);

  return [success, loading];
}

function useDataCollectionGet(collection, initData = [], orderBy = '') {
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState();
  const [data, setData] = useState(initData);

  useEffect(() => {
    if (!collection) return;

    setLoading(true);

    const collectionRef = orderBy
      ? db.collection(collection).orderBy(orderBy, 'desc').get()
      : db.collection(collection).get();

    collectionRef
      .then((querySnapshot) => {
        setSuccess(true);
        setData(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      })
      .catch((error) => {
        console.error('Error getting data from database', error);
        setSuccess(false);
      })
      .finally(() => setLoading(false));
  }, [collection]);

  return [data, success, loading];
}

function getCollection(collection, orderBy = '') {
  if (!collection) return;

  const collectionRef = orderBy
    ? db.collection(collection).orderBy(orderBy, 'desc').get()
    : db.collection(collection).get();

  return collectionRef
    .then((querySnapshot) => querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    .catch((error) => {
      console.error('Error getting data from database', error);
    });
}

function useDataItemGet(collection, item, initData = []) {
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState();
  const [data, setData] = useState(initData);

  useEffect(() => {
    if (!collection || !item) return;

    setLoading(true);

    db.collection(collection)
      .doc(item)
      .get()
      .then((doc) => {
        console.log(doc.data());
        setSuccess(true);
        setData({ ...doc.data() });
      })
      .catch((error) => {
        console.error('Error getting data from database', error);
        setSuccess(false);
      })
      .finally(() => setLoading(false));
  }, [collection, item]);

  return [data, success, loading];
}

function deleteDataItem(collection, item) {
  return db.collection(collection).doc(item).delete();
}

export {
  firebase,
  useAuthenticated,
  useFileUpload,
  deleteFile,
  formFileName,
  useDataAdd,
  useDataCollectionGet,
  useDataItemGet,
  deleteDataItem,
  getCollection,
};
