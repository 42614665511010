import React from 'react';
import { useDataCollectionGet } from '../../firestore';
import AdminListItem from './AdminListItem';
import AdminList from './AdminList';
import { Add } from '@material-ui/icons';

export default function AdminAlbums(props) {
  const [albums] = useDataCollectionGet('albums', [], 'releaseDateTime');

  return (
    <AdminList>
      {albums.map((album) => (
        <AdminListItem
          key={album.name}
          name={album.name}
          route={`album/${album.id}`}
        />
      ))}

      <AdminListItem route={`album/${null}`}>
        <Add />
      </AdminListItem>
    </AdminList>
  );
}
