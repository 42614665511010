import React, { useRef } from 'react';
import { Button } from '@material-ui/core';

export default function AdminFileInput({
  name,
  accept,
  handleChange,
  id,
  children,
  disabled,
  ...rest
}) {
  const fileInput = useRef(null);

  function createUid(id) {
    return id + '-' + (Math.random() * 10000).toString();
  }

  const uid = createUid(id);

  return (
    <span {...rest}>
      <input
        style={{ display: 'none' }}
        type="file"
        accept={accept}
        id={uid}
        ref={fileInput}
        onChange={(e) => handleChange(fileInput.current.files)}
      />
      <label htmlFor={uid}>
        <Button
          variant="outlined"
          color="primary"
          component="span"
          disabled={disabled}
        >
          {name}
          {children}
        </Button>
      </label>
    </span>
  );
}
