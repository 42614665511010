import React, { useState, useEffect } from 'react';
import {
  Card,
  TextField,
  Button,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { useDataAdd, useDataItemGet } from '../../firestore';
import SuccessStatus from './SuccessStatus';

const useStyles = makeStyles({
  container: {
    padding: '20px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  previewHeadline: {
    textDecoration: 'underline'
  }
});

export default function AdminStory() {
  const classes = useStyles();

  const [storyText, setStoryText] = useState('');
  const [storyData, setStoryData] = useState();

  const [initData] = useDataItemGet('story', 'pilvikone-story');

  const [dbAddSuccess, dbLoading] = useDataAdd(
    'story',
    'pilvikone-story',
    storyData
  );

  useEffect(() => {
    if (initData) {
      setStoryText(initData.text);
    }
  }, [initData]);

  function handleSubmit(event) {
    event.preventDefault();
    setStoryData({ text: storyText });
  }

  return (
    <Card>
      <form onSubmit={handleSubmit} className={classes.container}>
        <TextField
          fullWidth
          multiline
          label="Pilvikoneen tarina"
          rows="8"
          style={{ marginBottom: '20px' }}
          value={storyText}
          onChange={(e) => setStoryText(e.target.value)}
        />
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={dbLoading}
          >
            Tallenna
            {dbLoading && (
              <CircularProgress style={{ position: 'absolute' }} size={20} />
            )}
          </Button>
          <SuccessStatus success={dbAddSuccess} />
        </div>
      </form>

      <div className={classes.container}>
        <h2 className={`mb20 ${classes.previewHeadline}`}>Esikatselu</h2>
        <article dangerouslySetInnerHTML={{ __html: storyText }} />
      </div>
    </Card>
  );
}
