import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 250px)',
    gridGap: '1em',
    justifyContent: 'center',
    padding: '20px'
  }
}));

export default function AdminList(props) {
  const classes = useStyles();

  return <div className={classes.container}>
    {props.children}
  </div>
}
