import React from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '250px',
    height: '250px',
    background: '#fff',
    border: '1px solid #ccc',
  },
  itemName: {
    textAlign: 'center',
  },
}));

export default function AdminListItem({ name = '', route, children }) {
  const classes = useStyles();

  return (
    <ButtonBase
      className={classes.button}
      component={Link}
      to={`/Admin/${route}`}
    >
      <h3 className={classes.itemName}>{name}</h3>
      {children}
    </ButtonBase>
  );
}
