import React from 'react';
import { Router } from '@reach/router';
import AdminLogin from '../components/Admin/AdminLogin';
import AdminAppBar from '../components/Admin/AdminAppBar';
import PrivateRoute from '../components/Admin/PrivateRoute';
import AdminAlbums from '../components/Admin/AdminAlbums';
import AdminList from '../components/Admin/AdminList';
import AdminListItem from '../components/Admin/AdminListItem';
import AdminAlbum from '../components/Admin/AdminAlbum';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import fiLocale from 'date-fns/locale/fi';
import { useAuthenticated } from '../firestore';
import AdminStory from '../components/Admin/AdminStory';

const Hub = () => (
  <AdminList>
    <AdminListItem name="Albumit" route="albums" />
    <AdminListItem name="Tarina" route="story" />
  </AdminList>
);

const Album = () => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
    <AdminAlbum />
  </MuiPickersUtilsProvider>
);

export default function Admin() {
  const authenticated = useAuthenticated();

  return (
    <>
      <AdminAppBar show={authenticated} />

      <div className="pk-main-content">
        <Router basepath="/Admin">
          <AdminLogin path="/login" default />
          <PrivateRoute path="/hub" component={Hub} />
          <PrivateRoute path="/albums" component={AdminAlbums} />
          <PrivateRoute path="/album/:albumId" component={Album} />
          <PrivateRoute path="story" component={AdminStory} />
        </Router>
      </div>
    </>
  );
}
