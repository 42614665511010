import React from 'react';
import { Button, AppBar, Toolbar, makeStyles } from '@material-ui/core';
import { firebase } from '../../firestore';
import { Link } from 'gatsby';

const useStyles = makeStyles({
  appBarButton: {
    color: '#fff',
  },
});

export default function AdminAppBar({ show }) {
  const classes = useStyles();

  if (!show) {
    return null;
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <h2 style={{ color: '#fff' }}>Hallinnointi</h2>
        <span style={{ flex: '1 1 auto' }} />
        <Button
          className={classes.appBarButton}
          component={Link}
          to="/Admin/albums"
        >
          Albumit
        </Button>
        <Button
          className={classes.appBarButton}
          component={Link}
          to="/Admin/story"
        >
          Tarina
        </Button>
        <Button
          className={classes.appBarButton}
          onClick={() => firebase.auth().signOut()}
        >
          Kirjaudu ulos
        </Button>
      </Toolbar>
    </AppBar>
  );
}
