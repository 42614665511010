import React, { useState, useCallback, useEffect } from 'react';
import {
  TextField,
  makeStyles,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import AdminCoverUpload from './AdminCoverUpload';
import AdminTrackInput from './AdminTrackInput';
import { useDataAdd, useDataItemGet, deleteDataItem } from '../../firestore';
import { useParams } from '@reach/router';
import SuccessStatus from './SuccessStatus';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Link, navigate } from 'gatsby';

const useStyles = makeStyles({
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 10px 20px',
    width: '100%',
  },
  topSectionRight: {
    display: 'flex',
    flexDirection: 'column',
  },
  topSectionSave: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
  },
  tracks: {
    marginBottom: '40px',
  },
});

export default function AdminAlbum() {
  const classes = useStyles();

  const { albumId } = useParams();
  const [initAlbumData] = useDataItemGet('albums', albumId);

  const [albumName, setAlbumName] = useState('');
  const [albumNameDisabled, setAlbumNameDisabled] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [description, setDescription] = useState('');

  const [trackInputs, setTrackInputs] = useState([]);

  const [coverURL, setCoverURL] = useState('');
  const [coverName, setCoverName] = useState('');

  const [albumData, setAlbumData] = useState();

  const [dbAddSuccess, dbLoading] = useDataAdd('albums', albumName, albumData);

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (initAlbumData) {
      const {
        name,
        description,
        releaseDate,
        imgSrc,
        imgName,
        tracks,
      } = initAlbumData;
      if (name) {
        setAlbumName(name);
        setAlbumNameDisabled(true);
      }
      if (releaseDate) {
        setSelectedDate(new Date(releaseDate));
      }
      if (description) {
        setDescription(description);
      }
      if (imgSrc) {
        setCoverURL(imgSrc);
      }
      if (imgName) {
        setCoverName(imgName);
      }
      if (tracks) {
        setTrackInputs(tracks);
      }
    }
  }, [initAlbumData]);

  function handleSubmit(event) {
    event.preventDefault();

    const newAlbum = {
      name: albumName,
      release: selectedDate.getMonth() + 1 + '/' + selectedDate.getFullYear(),
      releaseDate: selectedDate.toISOString(),
      releaseDateTime: selectedDate.getTime().toString(),
      description,
      imgSrc: coverURL,
      imgName: coverName,
      tracks: trackInputs,
    };

    console.log(newAlbum);

    setAlbumData(newAlbum);
  }

  function addTrackInput() {
    const newTrack = {
      name: '',
      fileName: '',
      audioSrc: '',
      id: `trackinput-${trackInputs.length}`,
    };
    const newTracks = [...trackInputs, newTrack];
    setTrackInputs(newTracks);
  }

  function removeTrackInput(id) {
    const newTracks = trackInputs.filter((input) => input.id !== id);
    setTrackInputs(newTracks);
  }

  const handleTrackInputChange = useCallback(
    (key, value, id) => {
      const newInputs = trackInputs.map((input) => {
        if (input.id === id) {
          input[key] = value;
        }
        return input;
      });
      setTrackInputs(newInputs);
    },
    [trackInputs]
  );

  function reOrderList(array, startIndex, endIndex) {
    const newArray = Array.from(array);
    const [movedItem] = newArray.splice(startIndex, 1);
    newArray.splice(endIndex, 0, movedItem);

    return newArray;
  }

  function onDragEnd(result) {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    setTrackInputs(reOrderList(trackInputs, source.index, destination.index));
  }

  function deleteAlbum() {
    deleteDataItem('albums', albumName)
      .then(() => {
        console.log('Album: ' + albumName + ' deleted');
        navigate("/Admin/albums")
      })
      .catch((err) => console.error(err));
  }

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  return (
    <Card>
      <form className="pk-form" onSubmit={handleSubmit}>
        <section className={classes.topSection}>
          <Button component={Link} to="/Admin/albums">
            Takaisin
          </Button>
          <section className={classes.topSectionRight}>
            <div className={classes.topSectionSave}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!albumName || dbLoading}
                style={{ marginRight: '5px' }}
              >
                Tallenna
                {dbLoading && (
                  <CircularProgress
                    style={{ position: 'absolute' }}
                    size={20}
                  />
                )}
              </Button>
              <SuccessStatus success={dbAddSuccess} />
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDialogOpen}
            >
              Poista julkaisu
            </Button>
          </section>
        </section>

        <TextField
          fullWidth
          margin="normal"
          label="Nimi"
          type="text"
          value={albumName}
          onChange={(e) => setAlbumName(e.target.value)}
          disabled={albumNameDisabled}
        />
        <DatePicker
          value={selectedDate}
          onChange={setSelectedDate}
          disableFuture
          openTo="year"
          format="MM/yyyy"
          views={['year', 'month']}
          label="Julkaisuajankohta"
          fullWidth
        />
        <TextField
          fullWidth
          margin="normal"
          multiline
          rows="6"
          label="Kuvaus..."
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <AdminCoverUpload
          coverURL={coverURL}
          setCoverURL={setCoverURL}
          coverName={coverName}
          setCoverName={setCoverName}
        />

        <h2>Kappaleet</h2>
        <p>(Voit järjestää uudelleen raahaamalla)</p>
        <section className={classes.tracks}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'album-tracks-list'}>
              {(provided) => (
                <section ref={provided.innerRef} {...provided.droppableProps}>
                  {trackInputs.map((track, index) => (
                    <AdminTrackInput
                      key={track.id}
                      id={track.id}
                      index={index}
                      name={track.name}
                      fileName={track.fileName}
                      updateTrack={handleTrackInputChange}
                      removeTrack={removeTrackInput}
                    />
                  ))}
                  {provided.placeholder}
                </section>
              )}
            </Droppable>
          </DragDropContext>

          <Button onClick={addTrackInput} variant="outlined" color="primary">
            Lisää kappale
          </Button>
        </section>
      </form>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {'Poista koko julkaisu?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Oletko varma, että haluat poistaa koko julkaisun?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Peruuta
          </Button>
          <Button onClick={deleteAlbum} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
