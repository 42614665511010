import React from 'react';
import { green } from '@material-ui/core/colors';
import { Done, Error } from '@material-ui/icons';

export default function SuccessStatus({ success }) {
  if (success === null) {
    return null;
  }
  if (success) {
    return <Done style={{ color: green[500] }} />;
  } else {
    return <Error color="error" />;
  }
}
